<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">תצוגה מקדימה לשאלה {{ id }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <div v-html="model.question"></div>
                    <div class="question-answers">
                        <div v-for="(answer, idx) in model.answers" :key="idx" class="question-answer">
                            <input :checked="answer.isCorrect" type="radio" name="" id="">
                            <label v-html="answer.answer" for="question1"></label>
                        </div>
                    </div>
                    <b-field label="תשובה מפורטת">
                        <div v-html="model.detailedAnswer"></div>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import LessonService from "../services/LessonService";

export default {
    name: "DialogQuestionPreview",
    props: ["id", "store"],
    components: {
    },
    computed: {
        questionId() {
            return this.store.state.question?.id;
        },
    },
    created() {
        this.getQuestionPreview();
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            model: {
                question: null,
                answers: null,
                detailedAnswer: null
            }
        };
    },
    methods: {
        getQuestionPreview() {
            LessonService.getQuestionPreview(this.id)
                .then((r) => {
                    this.model = r.data;
                });
        },
    },
};
</script>

<style scoped lang="scss">
.question-answers {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    margin: 10px 0;
    pointer-events: none;
}

.question-answer {
    display: flex;
    align-items: center;
    gap: 5px;
}

.DialogReportPaid__Component__Loading {
    overflow: auto;
    max-height: 600px;
    // pointer-events: none;
}

.modal-card {
    // height: 400px;
}
</style>
