<template>
  <div class="Activity__Component">
    <div class="Activity__Component__Tabs">
      <div class="Activity__Component__Tabs__Item" @click="loadExercise()" :class="{ active: tab === 1 }">
        עריכה
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadExerciseQuestions()" :class="{ active: tab === 2 }">
        שאלות בתרגול
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadExerciseStatistics()" :class="{ active: tab === 3 }">
        סטטיסטיקה
      </div>
    </div>
    <div class="Activity__Component__TabsContent">
      <!-- <new-activity ref="noteActivity" v-if="showNewNote" type="Note" @onClose="close" @onSave="saveNote" />
      <new-activity ref="taskActivity" v-if="showNewTask" type="Task" show-title="true" @onClose="closeTask"
        @onSave="saveTask" /> -->
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
      <div v-if="tab === 1">
        <div class="Activity__Component__TabsContent__Tasks">
          <div v-if="exercise" class="Activity__Component__TabsContent__Tasks__Content">

            <div class="item">
              <div class="exercise-buttons">
                <b-button @click="getPrintExerciseAnswersUrl">חוברת בחינה (פתרון)</b-button>
                <b-button @click="getPrintExerciseUrl">חוברת בחינה</b-button>
                <b-button disabled>ייצוא לWORD</b-button>
                <b-button @click="reCalculateGrades()" type="is-danger">עדכון נתוני תרגול</b-button>
                <b-button @click="exportExerciseQuestions" type="is-info">ייצוא שאלות לאקסל</b-button>
              </div>
            </div>

            <div class="item">
              <div style="display: flex; gap: 10px;">
                <b-field label="שם התרגול">
                  <b-input style="min-width: 300px" v-model="exercise.name"></b-input>
                </b-field>
                <b-field label="סדר">
                  <b-input v-model="exercise.sortOrder"></b-input>
                </b-field>
                <b-field label="קטגוריה">
                  <v-select style="min-width: 240px;" dir="rtl" v-model="exercise.exerciseCategoryId" :filterable="true"
                    :clearable="true" :reduce="item => item.id" :getOptionLabel="(item) => `${item.name}`"
                    :options="categories" :searchable="true" :close-on-select="true" :placeholder="'בחר ערך מהרשימה'">
                  </v-select>
                </b-field>
                <b-field label="סוג תרגול">
                  <v-select style="min-width: 240px;" dir="rtl" v-model="exercise.exerciseTypeId" :filterable="true"
                    :clearable="true" :reduce="item => item.id" :getOptionLabel="(item) => `${item.name}`"
                    :options="exerciseTypes" :searchable="true" :close-on-select="true"
                    :placeholder="'בחר ערך מהרשימה'">
                  </v-select>
                </b-field>
                <b-field label="סטטוס">
                  <b-switch class="ltr" v-model="exercise.status">{{
                    exercise.status ? "פעיל" : "לא פעיל"
                  }}</b-switch>
                </b-field>
              </div>
            </div>
            <div class="item">
              <b-field label="חוקים להצגה">
                <!-- <FieldInlineSelect :multiple="true" :closeOnSelect="false" :hideHover="true"
                  style="border: 1px solid gray; width: 30%;" :filterable="true" :clearable="true" :optionsAsync="getLaws"
                  v-model="exercise.categoryId" /> -->
                <v-select dir="rtl" v-model="exercise.displayLawIds" :filterable="true" :clearable="true"
                  :reduce="item => ({ id: item.id, name: item.name })" :getOptionLabel="(item) => `${item.name}`"
                  :options="laws" :multiple="true" :searchable="true" :close-on-select="false"
                  :placeholder="'בחר ערך מהרשימה'">
                </v-select>
              </b-field>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.insertMethodOnline">הזנה אונליין</b-checkbox>
                <b-checkbox class="bold-text" v-model="exercise.insertMethodOffline">הזנה בדיעבד</b-checkbox>
              </b-field>
              <small>*יש לבחור לפחות שיטת הזנה אחת</small>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.displaySeperateLawText">הצג כל חוק בנפרד</b-checkbox>
              </b-field>
              <small>רלוונטי לתרגול חקיקה דינאמי בלבד</small>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.disableBackButton">הסתרת כפתור "הקודם" בזמן
                  תרגול</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">נתיב של האייקון:</span><b-input v-model="exercise.iconUrl"></b-input>
              </div>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox dir="rtl" class="bold-text" v-model="exercise.isAllowedToPrint">האם לאפשר פתרון להדפסה</b-checkbox>
                <b-checkbox dir="rtl" style="margin-right: 5px;" class="bold-text" v-model="exercise.isAllowedToPrintSolution">האם לאפשר שאלון
                  להדפסה</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.isTofillStudendFormInOfflineMode">מילוי תשובות נכונות
                  בהזנה בדיעבד</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <div class="bold-text">הנחיות (יופיעו לתלמיד לפני התרגול ובמהלכו)
              </div>
              <ckeditor :editor="editor" v-model="exercise.disclaimerText" :config="editorConfig"></ckeditor>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.showAvgAndStdev">הצגת ממוצע אמת, סטיית תקן ומספר מזינים
                  בדו"ח
                  תוצאות לתלמיד</b-checkbox>
                <b-checkbox class="bold-text" v-model="exercise.isToShowAndCalcTimeCol">הצגת עמודת זמן בדו"ח
                  תרגול</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">מדד תקין עורך:</span><b-input v-model="exercise.madadTakinEditor"></b-input>
              </div>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">סיסמה לתרגול:</span><b-input v-model="exercise.enterPassword"></b-input>
                <small>(*עד 6 תווים. *שדה הזנת הסיסמה יופיע לתלמיד בהנחיות לפני התרגול)</small>
              </div>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.isHidden">האם מוסתר?</b-checkbox>
                <p>תלמידים יוכלו לגשת לתרגול אך ורק באמצעות לינק ישיר.</p>
              </b-field>
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.showInLawCenter">האם להציג במרכז חקיקה?</b-checkbox>
                <p>אם התרגול מוסתר אבל רוצים שהחוק יוצג בכל זאת במרכז חקיקה.</p>
              </b-field>
            </div>

            <!-- <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.status">האם להסתיר דו"ח תוצאות?</b-checkbox>
                <p>לאחר סיום התרגול התלמידים לא יראו את דו"ח התוצאות</p>
              </b-field>
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span>טקסט להצגה במצב של דו"ח תוצאות מוסתר:</span><b-input v-model="exercise.status"></b-input>
              </div>
              <b-checkbox v-model="exercise.status">האם להציג ציון במצב של דו"ח תוצאות מוסתר?</b-checkbox>
            </div> -->

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.isNoshem">האם תרגול נושם?</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.allowUserReset">האם לאפשר למשתמשים לאפס
                  תרגול?</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.allowImmediateFeedback">האם לתת פידבק מידי
                  לתשובה?</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">מקבץ חקיקה מספר:</span><b-input v-model="exercise.lawCollectionId"></b-input>
              </div>
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.hideExpansions">האם להסתיר הרחבות??</b-checkbox>
              </b-field>
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.areExpansionsClosedByDefault">האם להציג הרחבות סגורות
                  כברירת מחדל?</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.scrollToLawClause">האם לגלול לסעיף חוק
                  בשאלה?</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.hideTimeInExercise">האם להסתיר זמן בתרגול?</b-checkbox>
              </b-field>
            </div>

            <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="exercise.hideAreYouSure">האם להסתיר שאלה האם אתה
                  בטוח?</b-checkbox>
              </b-field>
            </div>

            <div class="exercise-save-buttons">
              <b-button type="is-info" class="new flex-2" @click="saveExercise">שמירת שינויים</b-button>
              <b-button type="is-success" :disabled="true" class="new flex-2" @click="newExercise">מחולל
                הבחינה</b-button>
              <a class="flex-1" @click="duplicateExercise">שכפול תרגול</a>
            </div>

          </div>
        </div>
      </div>
      <div class="questions__tab" v-if="tab === 2">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <div v-if="exerciseQuestions.length" style="display: flex; gap: 10px; margin-bottom: 10px;">
              <div class="bold-text">שאלות בתרגול</div>
              <b-button @click="setHideAnswers(true)" style="height: 25px;">הסתר חלופות</b-button>
              <b-button @click="setHideAnswers(false)" style="height: 25px;">הצג חלופות</b-button>
            </div>
            <div class="new bold-text">
              <b-button type="is-info" v-if="isReOrderColumn" @click="saveNewQuestionsOrder">שמור</b-button>
              <b-button @click="isReOrderColumn = !isReOrderColumn">סדר מחדש</b-button>
              <span>
                רמת קושי ממוצעת בתרגול: {{ averageDifficultyLevel || '---' }}
              </span>
            </div>
          </div>
          <div class="section-container">
            <small>מספר שאלות: {{ exerciseQuestions.length }} (*לא ניתן לסדר שאלות לתרגול פעיל)</small>
            <table v-if="exerciseQuestions.length" id="customers" class="">
              <tr>
                <th style="width: 90px; text-align: center;">סדר</th>
                <th style="width: 100px;">ID שאלה</th>
                <th style="width: 780px;">שאלה</th>
                <th>הערות עורך</th>
                <th style="width: 80px">רמת קושי</th>
                <th style="text-align: center; width: 55px;">עריכה</th>
                <th style="text-align: center; width: 55px;">מחיקה</th>
                <th style="text-align: center; width: 55px;">תצוגה</th>
                <th v-if="isReOrderColumn" style="text-align: center; width: 80px;">סדר חדש</th>
              </tr>
              <tr class="row" v-for="(question, idx) in exerciseQuestions" :key="idx">
                <td style="width: 90px" class="column-item">
                  <div style="justify-content: center;" class="column-item flex">
                    <span v-if="!exercise.status" @click="setQuestionSortOrder(question, idx, 1)"><b-icon
                        size="is-small" icon="arrow-down" class="sort__order__button">+</b-icon></span>
                    <span class="sort__order__value">{{ question.sortOrder }}</span>
                    <span v-if="!exercise.status" @click="setQuestionSortOrder(question, idx, -1)"><b-icon
                        size="is-small" icon="arrow-up" class="sort__order__button">-</b-icon></span>
                  </div>
                </td>
                <td style="width: 100px" class="column-item">{{ question.questionId }}
                  <span class="exercise-tooltip" v-if="question.tooltipMessage">
                    <b-tooltip type="is-white" :label="question.tooltipMessage">
                      <b-icon style="color: #a94442;" icon="info-circle" pack="fas"></b-icon>
                    </b-tooltip>
                  </span>
                </td>
                <td v-html="question.questionName" style="width: 780px;" class="column-item ellipsis-div"></td>
                <td class="column-item align-justify ellipsis-div">{{ question.editorNotes }}</td>
                <td style="width: 80px;" class="column-item align-justify">{{ question.difficultyLevel }}</td>
                <td style="text-align: center; width: 55px;">
                  <b-tooltip type="is-white" label="הגדרת שאלה">
                    <a :href="`question/${question.questionId}`" target="_blank"
                      @click="goToQuestion(question.questionId)" class="updateItem">
                      <b-icon icon="pen"> </b-icon>
                    </a>
                  </b-tooltip>
                </td>
                <td style="text-align: center; width: 55px;">
                  <b-tooltip type="is-white" label="מחק שאלה">
                    <a style="color: red;" @click="removeQuestionFromExercise(question.questionId)" class="updateItem">
                      <b-icon icon="delete"> </b-icon>
                    </a>
                  </b-tooltip>
                </td>
                <td style="text-align: center; width: 55px;">
                  <a @click="previewQuestion(question.questionId)" style="color: gray;" class="updateItem">
                    <b-icon icon="eye"> </b-icon>
                  </a>
                </td>
                <td v-if="isReOrderColumn" style="width: 80px; text-align: center;" class="column-item"><input
                    style="width: 25px;" type="number" v-model="question.newSortOrder"></td>
              </tr>
            </table>
          </div>
          <div class="item">
            <b-field label="בחר שאלה">
              <div style="gap: 15px" class="flex">
                <b-input v-model="filterSearch.Terms" placeholder='חיפוש חופשי'></b-input>
                <b-button style="height: 34px" @click="isFiltersShown = !isFiltersShown" type="is-info">מתקדם</b-button>
                <b-button style="height: 34px" @click="onSearchNewQuestions">חיפוש</b-button>
              </div>
            </b-field>
            <div v-if="isFiltersShown" class="filters">
              <div class="flex space-between">
                <b-input style="width: 150px" v-model="filterSearch.MinimumStudentAnswers" placeholder="עונים מינימלית"
                  type="number"></b-input>
                <b-checkbox v-model="filterSearch.HasOpenRequestsOnly">שאלות עם פניות פתוחות</b-checkbox>
                <b-checkbox v-model="filterSearch.IsActive">פעילות בלבד</b-checkbox>
                <b-checkbox v-model="filterSearch.IsInactive">לא פעילות בלבד</b-checkbox>
                <b-checkbox v-model="filterSearch.HasEditorNotesOnly">שאלות עם הערות עורך</b-checkbox>
              </div>
              <div style="margin-top: 10px;" class="flex space-between">
                <FieldInlineSelect class="search-select" style="width: 200px" :hide-hover="true" label=""
                  :clearable="true" :filterable="false" :optionsAsync="getQuestionCategories" @onChange="onChange"
                  placeholder="קטגוריות" v-model="filterSearch.QuestionCategoryIds" />
                <FieldInlineSelect class="search-select" style="width: 200px" :hide-hover="true" label=""
                  :clearable="true" :filterable="false" :optionsAsync="getAcademicInstitude" @onChange="onChange"
                  placeholder="מוסד אקדמי" v-model="filterSearch.AcademicInstituteId" />
                <FieldInlineSelect class="search-select" style="width: 200px" :hide-hover="true" label=""
                  :clearable="true" :filterable="false" :optionsAsync="getAuthors" @onChange="onChange"
                  placeholder="מחבר" v-model="filterSearch.AuthorId" />
              </div>
            </div>
            <div class="new-questions" v-if="newQuestionIds.length">
              <div :key="question.id" v-for="question in newQuestionIds" class="new-question flex">
                <b-checkbox v-model="question.isChecked"></b-checkbox>
                <div>{{ question.id }}</div>
                <div v-html="question.question"></div>
                <div>{{ question.editorNotes }}</div>
              </div>
            </div>
            <b-button style="margin-top: 10px" @click="addNewQuestionsToExercise" v-if="newQuestionsChecked.length">
              הוסף שאלות לתרגול
            </b-button>
          </div>
        </template>
      </div>
      <div v-if="tab === 3">
        <div class="exercise-statistics" v-if="exerciseStatistics">
          <div class="exercise-statistics-query">
            <FieldInlineDate label="" @onChange="onChange" v-model="exerciseStatisticsFrom" />

            <FieldInlineDate label="" @onChange="onChange" v-model="exerciseStatisticsTo" />

            <b-button @click="loadExerciseStatistics" type="is-info">חפש</b-button>
          </div>
          <div class="exercise-statistics-content">
            <div class="item">
              מספר מתרגלים שסיימו {{ exerciseStatistics.studentsExercise }} מתוך {{ exerciseStatistics.totalStudents }}
              <div>
                <progress dir="ltr" id="file" :value="exerciseStatistics.studentsExercise"
                  :max="exerciseStatistics.totalStudents"></progress>
              </div>
            </div>
            <div class="item">
              ממוצע ציונים: {{ exerciseStatistics.grade ? exerciseStatistics.grade.toFixed(2) : 0 }}
            </div>
            <div class="item">
              זמן תרגול ממוצע: {{ exerciseStatistics.averageTime }}
            </div>
            <div class="item">
              המזהה לשאלה עם כמות השגיאות הגדולה ביותר: {{ exerciseStatistics.maxErrorQuestion }}
              <div><b-tooltip position="is-bottom" multilined>
                  <b-button label="לפרטים" dashed />
                  <template v-slot:content>
                    <div v-html="exerciseStatistics.questionDescription"></div>
                  </template>
                </b-tooltip></div>
            </div>
            <div class="item">
              ייצוא נתונים לאקסל
              <div><button>יצוא</button></div>
            </div>
            <div class="item">
              אחוז הזנה אונליין: {{ exerciseStatistics.onlineInsertPercent }}
              <div>
                אחוז הזנה בדיעבד: {{ exerciseStatistics.offlineInsertPercent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import { ContentLoader } from "vue-content-loader";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import CoursesService from "@/services/CoursesService";
// import UserService from "@/services/UserService";
import _ from "lodash";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { ModalProgrammatic as Modal } from "buefy";
import DialogQuestionPreview from "./DialogQuestionPreview.vue";
import LessonService from "../services/LessonService";
import DialogAddExercise from "./DialogAddExercise.vue";
import DialogDuplicateExercise from "./DialogDuplicateExercise.vue";
import AdminService from "../services/AdminService";
import { saveAs } from "file-saver";


export default {
  name: "Activity",
  components: {
    FieldInlineDate,
    FieldInlineSelect,
    vSelect
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  // updated() {
  //   const params = window.location.search.substring(1);
  //   const lastIdx = params.lastIndexOf("=");
  //   const taskId = params.slice(lastIdx + 1);
  //   const task = this.$refs[`taskElement${taskId}`][0];
  //   task.isOpen = true;
  //   this.$nextTick(() => {
  //     task.$el.scrollIntoView({ behavior: "smooth" });
  //   });
  // },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
    newQuestionsChecked() {
      return this.newQuestionIds.filter((q) => q.isChecked);
    },
    // exercise() {
    //   return this.$store.state.exercise
    // },
    exerciseDifficultyAverage() {
      var sum = 0;
      this.exerciseQuestions.forEach((e) => sum += e.difficultyLevel);
      return (sum / this.exerciseQuestions.length).toFixed(1);
    },
    exerciseTypes() {
      return [
        {
          id: 0,
          name: 'רגיל'
        },
        {
          id: 1,
          name: 'חוק מלא'
        },
        {
          id: 2,
          name: 'חוק דינאמי'
        },
        {
          id: 3,
          name: 'מיקודית'
        },
        {
          id: 4,
          name: 'סימולציה'
        }
      ]
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    lesson() {
      return this.$store.state.lesson;
    },
    userName() {
      return `${this.$store.state.user.privateName} ${this.$store.state.user.lastName}`;
    },
    haveNoActivities() {
      return (
        !this.loading &&
        (!this.groupedActivities || !Object.keys(this.groupedActivities).length)
      );
    },
    haveNoNotes() {
      return (
        !this.loading &&
        (!this.groupedActivitiesNotes ||
          !Object.keys(this.groupedActivitiesNotes).length)
      );
    },
    haveNoTasks() {
      return (
        !this.loading &&
        (!this.groupedActivitiesTasks ||
          !Object.keys(this.groupedActivitiesTasks).length)
      );
    },
    groupedActivities() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) => this.deletedItems.indexOf(item.Id) === -1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesNotes() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesTasks() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 2
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
      isLoading: false,
      exercise: null,
      averageDifficultyLevel: null,
      exerciseQuestions: null,
      exerciseStatistics: null,
      exerciseStatisticsFrom: moment().subtract(6, 'months'),
      exerciseStatisticsTo: new Date(),
      laws: [],
      isFiltersShown: false,
      isReOrderColumn: false,
      categories: [],
      verdicts: null,
      newQuestionIds: [],
      questionCategories: [],
      editor: ClassicEditor,
      editorConfig: {
        language: {
          ui: 'he',
        }
      },
      filterSearch: {
        Terms: null,
        AcademicInstituteId: null,
        AuthorId: null,
        QuestionCategoryIds: null,
        MinimumStudentAnswers: null,
        HasOpenRequestsOnly: null,
        IsActive: null,
        IsInactive: null,
        HasEditorNotesOnly: null
      }
    };
  },
  created() {
    this.getLaws();
    this.getCategories();
    this.loadExercise();
  },
  methods: {
    onDelete(id) {
      this.deletedItems.push(id);
    },
    getPrintExerciseUrl() {
      window.open(`/course/${this.course.id}/exercisePrint/${this.entityId}`, '_blank');
    },
    getPrintExerciseAnswersUrl() {
      window.open(`/course/${this.course.id}/exercisePrint/${this.entityId}?showAnswers=${true}`, '_blank');

    },
    saveNewQuestionsOrder() {
      let newMappedQuestions = [...this.exerciseQuestions];
      newMappedQuestions = newMappedQuestions.map((q) => ({
        id: q.questionId,
        sortOrder: q.newSortOrder
      }));
      this.isLoading = true;
      LessonService.setNewOrderToExerciseQuestions(this.exercise.id, newMappedQuestions)
        .then(() => {
          this.loadExerciseQuestions();
        }).catch(() => {
        }).finally(() => {
          this.isLoading = false;
        });
    },
    setQuestionSortOrder(currQuestion, idx, action) {
      const currentQuestion = currQuestion;
      const questionToChange = this.exerciseQuestions[idx + action];
      if (!questionToChange) return;
      if (action === 1) {
        questionToChange.sortOrder = currentQuestion.sortOrder;
        currentQuestion.sortOrder += 1;
      } else {
        questionToChange.sortOrder = currentQuestion.sortOrder;
        currentQuestion.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentQuestion.id,
          sortOrder: currentQuestion.sortOrder,
        },
        itemToChange: {
          id: questionToChange.id,
          sortOrder: questionToChange.sortOrder,
        }
      }
      this.isLoading = true;
      LessonService.setExerciseQuestionsSortOrder(model)
        .then(() => {
          this.loadExerciseQuestions();
          this.isLoading = false;
        });
    },
    exportExerciseQuestions() {
      this.isLoading = true;
      AdminService.exportExerciseQuestion({
        ExerciseId: +this.entityId
      }).then((r) => {
        this.saveFile(r.data, "יצוא שאלות בתרגול ממערכת SimAdmin");
        this.isLoading = false;
      });
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    reCalculateGrades() {
      this.isLoading = true;
      LessonService.reCalculateGrades(this.entityId)
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: `העדכון בוצע בהצלחה!`,
            duration: 4000,
          });
          window.location.reload();
        }).catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: `העדכון נכשל!`,
            duration: 4000,
          });
        }).finally(() => {
          this.isLoading = false;
        });
    },
    previewQuestion(id) {
      Modal.open({
        component: DialogQuestionPreview,
        customClass: "entity-modal",
        props: {
          id: id,
          store: this.$store
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    goToQuestion(id) {
      window.open(`/question/${id}`);
    },
    removeQuestionFromExercise(id) {
      this.$buefy.dialog.confirm({
        title: 'מחיקת שאלה',
        message: 'האם למחוק את השאלה?',
        confirmText: 'כן',
        cancelText: 'לא',
        type: 'is-danger',
        closeOnConfirm: false,
        hasIcon: true,
        onConfirm: (a, instance) => {
          this.isLoading = true;
          LessonService.removeExerciseQuestion(this.entityId, this.$store.state.auth.course?.id, [
            id
          ]).then(() => {
            this.$buefy.toast.open({
              type: "is-success",
              message: `השאלה הוסרה בהצלחה!`,
              duration: 4000,
            });
            this.loadExerciseQuestions();
            instance.close();
          }).catch(() => {
            this.$buefy.toast.open({
              type: "is-danger",
              message: `העדכון נכשל!`,
              duration: 4000,
            });
          }).finally(() => {
            this.isLoading = false;
          });
        },
      });
    },
    addNewQuestionsToExercise() {
      this.isLoading = true;
      LessonService.updateExerciseQuestions(this.entityId, this.$store.state.auth.course?.id,
        this.newQuestionsChecked.map((q) => q.id)
      ).then(() => {
        this.$buefy.toast.open({
          type: "is-success",
          message: `העדכון בוצע בהצלחה!`,
          duration: 4000,
        });
        this.exercise.newQuestionIds = null;
        this.loadExerciseQuestions();
      }).catch(() => {
        this.$buefy.toast.open({
          type: "is-danger",
          message: `העדכון נכשל!`,
          duration: 4000,
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    onChange() { },
    onSearchNewQuestions() {
      if (!this.filterSearch.Terms) return;
      this.isLoading = true;
      return AdminService.getQuestionsInBank({
        ... this.filterSearch,
        QuestionCategoryIds: this.filterSearch.QuestionCategoryIds ? [this.filterSearch.QuestionCategoryIds] : null,
        PageSize: 999,
        IsAscending: false,
        PageNumber: 1,
        ResultType: 3
        ,
      })
        .then((r) => {
          if (!r.data.items.length) {
          } else {
            this.newQuestionIds = r.data.items;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getLaws() {
      CoursesService.getLaws().then((r) =>
        this.laws = r.data.map((i) => ({ id: i.id, name: i.name }))
      );
    },
    getAcademicInstitude() {
      return () =>
        AdminService.getAcademicInstitudes().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
        );
    },
    getQuestionCategories() {
      return () =>
        AdminService.getQuestionCategories().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
        );
    },
    getAuthors() {
      return () =>
        AdminService.getAuthors().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
        );
    },
    getCategories() {
      CoursesService.getCategories().then((r) =>
        this.categories = r.data.map((i) => ({ id: i.id, name: i.name }))
      );
    },
    close() {
      this.showNewNote = false;
    },
    closeTask() {
      this.showNewTask = false;
    },
    newNote() {
      this.showNewNote = true;
    },
    newTask() {
      this.showNewTask = true;
    },
    saveExercise() {
      this.isLoading = true;
      // if (this.exercise.displayLawIds) {
      //   this.exercise.displayLawIds = this.exercise.displayLawIds.map((l) => ({
      //     id: l.Value,
      //     name: l.Text
      //   }));
      // }
      LessonService.updateExerciseContent(this.entityId, this.$store.state.auth.course?.id, {
        ...this.exercise
      }).then(() => {
        this.$buefy.toast.open({
          type: "is-success",
          message: `העדכון בוצע בהצלחה!`,
          duration: 4000,
        });
        window.location.reload();
      }).catch(() => {
        this.$buefy.toast.open({
          type: "is-danger",
          message: `העדכון נכשל!`,
          duration: 4000,
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    setHideAnswers(bool) {
      this.isLoading = true;
      LessonService.setHideAnswers(this.course.id, this.entityId, bool)
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: `העדכון בוצע בהצלחה!`,
            duration: 4000,
          });
        }).catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: `העדכון נכשל!`,
            duration: 4000,
          });
        }).finally(() => {
          this.isLoading = false;
        })
    },
    newExercise() {
      Modal.open({
        component: DialogAddExercise,
        customClass: "entity-modal",
        props: {

        },
        canCancel: false,
        trapFocus: true,
      });
    },
    duplicateExercise() {
      Modal.open({
        component: DialogDuplicateExercise,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          exerciseId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    loadExercise() {
      this.isLoading = true;
      LessonService.getExercise(this.entityId, this.$store.state.auth.course?.id).then((r) => {
        this.exercise = r.data;
        this.exercise.displayLawIds = this.exercise.displayLawIds.map((l) => ({ id: l.id, name: l.name }));
      })
        .finally(() => {
          if (this.tab == 1 && this.exercise.questionsCount > 0) {
            this.loadExerciseQuestions();
          } else {
            this.tab = 1;
            this.isLoading = false;
          }
        });
    },
    loadExerciseQuestions() {
      this.isLoading = true;
      AdminService.getExerciseQuestionsInBank({
        ExerciseId: +this.entityId,
        PageSize: 999,
        SortedBy: "Id",
        IsAscending: true
      })
        .then((r) => {
          this.exerciseQuestions = r.data.items;
          this.exerciseQuestions.forEach((q) => q.newSortOrder = q.sortOrder);
          this.averageDifficultyLevel = r.data.averageDifficultyLevel;
        })
        .finally(() => {
          this.tab = 2;
          this.isLoading = false;
        });
    },
    loadExerciseStatistics() {
      this.isLoading = true;
      LessonService.getExerciseStatistics(this.course.id, this.entityId, {
        FromDatepickerSearch: this.exerciseStatisticsFrom,
        ToDatepickerSearch: this.exerciseStatisticsTo
      })
        .then((r) => {
          this.exerciseStatistics = r.data;
        })
        .finally(() => {
          this.tab = 3;
          this.isLoading = false;
        });
    },
    loadVerdicts() {
      this.isLoading = true;
      LessonService.getAdminVerdicts(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.verdicts = r.data.items;
        })
        .finally(() => {
          this.tab = 4;
          this.isLoading = false;
        });
    },
    parsedGroupedDate(date) {
      if (date) {
        const localLocale = moment(date, "MM/YYYY");
        localLocale.locale("he");
        return localLocale.format("MMMM YYYY");
      }
      return null;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped lang="scss">
.Activity__Component {
  background-color: #fff;
}

::v-deep .exercise-tooltip .tooltip-content {
  margin-left: -200px;
}

::v-deep br {
  display: none;
}

progress {
  height: 30px;
  border-radius: 5px;
  width: 400px;
  position: relative;
  background-color: orange;
}

progress::-webkit-progress-bar {
  background-color: #eee;
}

.search-select {
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  box-shadow: inset 0 0.0625em 1.125em rgba(10, 10, 10, 0.05);
}

progress::-webkit-progress-value {
  background-color: green;
}

.exercise-statistics-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    width: 45%;
    height: 130px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.exercise-statistics-query {
  display: flex;
  gap: 20px;
}

.exercise-buttons {
  display: flex;
  justify-content: space-between;
}

.item {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding: 20px 10px;
  background-color: white;
}

.flex {
  display: flex;
}

.new-questions {
  max-height: 400px;
  overflow: auto;
}

.new-question {
  align-items: flex-start;
  background-color: #eee;
  padding: 10px 0;
  margin-top: 7px;
  gap: 10px;
}

.questions__tab {
  margin-bottom: 400px
}

.align-justify {
  text-align: justify;
}

.bold-text {
  font-weight: bold;
}

.ellipsis-div {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.sort__order__button {
  padding: 5px;
  background-color: #1eb2d0;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  height: 17px;
  width: 17px;
}

.sort__order__value {
  width: 15px;
  margin: 0 10px;
  display: inline-block;
  text-align: center;
}

.filters {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #fff;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  // margin: 0 2% 50px 3%;

  // overflow-x: hidden;
  // align-items: center;
  &:last-child {
    // margin: 0 5% 0 5%;
  }

  img {
    height: 15px;
    margin: 0 2px;
  }

  h1 {
    // margin: 0 2%;
    // width: 15%;
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding: 0px 1%;
    width: 83%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-word;

    ::v-deep .title {
      display: none;
    }
  }

  .workplan {
    display: flex;
    flex-direction: column;

    span {
      // display: flex;
      // align-items: center;
      width: fit-content;
    }

  }
}


#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #1E9EB8;
  color: white;
}

#customers {
  // max-height: 450px;
  // overflow: auto;
  height: 100%;
  margin: 15px 0;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

table {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 400px;
}

thead {
  padding-right: 13px;
  flex: 0 0 auto;
}

tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.document-table-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  // margin-bottom: 25px;
  .document-table-title {
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
  }

  .label-upload-file {
    margin-left: 20px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #57b7ca;
    cursor: pointer;
  }
}

.document-table {
  display: table-caption;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 70px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 5px;
  margin-top: 15px;
  max-height: 450px;
  overflow: auto;

  .columns {
    font-weight: bold;
    background-color: #E98F12;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .columns,
  .row {
    // display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 10px 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;
    }
  }
}

.Activity__Component__TabsContent {
  padding: 20px 30px;
  background-color: #eeeeee;

  & .Activity__Item {
    margin: 8px 0;
  }
}

.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}

.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;

    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}

.new {
  display: flex;
  align-items: center;
  gap: 10px;
}

button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  // cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: #4d6782;
  }
}

.exercise-save-buttons {
  display: flex;
  justify-content: space-around;
  gap: 100px;
  margin-top: 15px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: space-between;
}

.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
