<template>
  <div class="" v-if="ready">
    <span v-if="loading" class="loading">
      <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
    </span>
    <div v-if="lecturesStudentModel && lecturesStudentModel.length" class="StudentGroups__Container">
      <div class="StudentGroupsWidget__Component"  v-for="lecture in lecturesStudentModel" :key="lecture.id">
        <p>{{ lectureItem(lecture) }}</p>
      </div>
    </div>
    <div v-if="!loading && !lecturesStudentModel.length">אין הרצאות משויכות למשתמש</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";
import { ModalProgrammatic as Modal } from "buefy";
import UserService from "../../services/UserService";

export default {
  name: "ClientWidget",
  props: ["ready", "namespace", "fetch"],
  components: {
    ClipLoader,
  },
  data() {
    return {
      lecturesStudentModel: null,
      loading: false
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["id"]),
    };
  },
  created() {
    // if (this.id) {
    //   UserService.getUserLectures(this.id)
    //     .then((res) => {
    //       this.lecturesStudentModel = res.data;
    //     });
    // }
  },
  computed: {
  },
  methods: {
    displayDate() {
      return moment(this.CreateDate).format("DD/MM/YYYY");
    },
    lectureItem(lecture) {
      return `בקורס ${lecture.courseName}. במסלול ${lecture.lectureLearningTrackName}. בנושא ${lecture.lectureLearningSubjectName} להרצאה בתאריך ${lecture.lectureStartTime} עם המרצה: ${lecture.lectureTeacherName}`;
    },
    init() {
      if (this.id) {
        this.loading = true;
        UserService.getUserLectures(this.id)
          .then((res) => {
            this.lecturesStudentModel = res.data;
          }).finally(() => {
            this.$emit('onReady', { titleCount: this.lecturesStudentModel?.length });
            this.loading = false;
          });
          this.$emit("onReady", true);
      }
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.StudentGroups__Container {
  max-height: 400px;
  padding: 0 10px;
  overflow: auto;
}

.StudentGroupsWidget__Component {
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
