<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">שכפול תרגול</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="קורס:">
                        <FieldInlineSelect label="קורס" @onChange="onChange" :filterable="true" :clearable="true"
                            :optionsAsync="getCourses" v-model="model.courseId" />
                    </b-field>
                    <b-field v-if="model.courseId" label="יחידת לימוד:">
                        <FieldInlineSelect label="יחידת לימוד" @onChange="onChange" :filterable="true" :clearable="true"
                            :optionsAsync="getCourseLessons" v-model="model.lessonId" />
                    </b-field>
                </div>
                <div style="margin-top: 20px;">
                    <h1>שימו לב:</h1>
                    <div>1. התרגול ישוכפל ליחידת הלימוד הרצויה</div>
                    <div>2. התרגול המשוכפל יהיה בסטטוס 0</div>
                    <div>2. באפשרותך לשכפל תרגול רק לקורסים להם יש לך הרשאות מנהל </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
// import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import CoursesService from "../services/CoursesService";
import LessonService from "../services/LessonService";

export default {
    name: "DialogDuplicateExercise",
    props: ["exerciseId", "store"],
    components: {
        FieldInlineSelect
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            model: {
                courseId: null,
                lessonId: null
            }
        };
    },
    methods: {
        save() {
            if (this.model.courseId && this.model.lessonId) {
                this.isLoading = true;
                LessonService.duplicateExercise(this.exerciseId, this.courseId, {
                    ToCourseId: this.model.courseId,
                    ToLessonId: this.model.lessonId
                })
                    .then(() => {
                        this.$buefy.toast.open({
                            type: "is-success",
                            message: `השכפול בוצע בהצלחה!`,
                            duration: 4000,
                        });
                        // window.location.reload();
                        this.$emit('close');
                    }).catch(() => {
                        this.$buefy.toast.open({
                            type: "is-danger",
                            message: `השכפול נכשל!`,
                            duration: 4000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        getCourses() {
            return () =>
                CoursesService.getCourses(true).then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
                );
        },
        getCourseLessons() {
            return () =>
                CoursesService.getCourse(
                    this.model.courseId,
                    {
                        PageSize: 999,
                        IsAscending: false,
                        PageNumber: 1,
                        ResultType: 3
                    },
                    false
                ).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
                );
        },
        onChange() { }
    },
};
</script>

<style lang="scss"></style>
