var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("שיוך וידאו ליחידת לימוד")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogReportPaid__Component__Loading" },
          [
            _c("FieldInlineText", {
              attrs: { label: "שם" },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name"
              }
            }),
            _c("FieldInlineSelect", {
              attrs: {
                label: "יחידת לימוד",
                filterable: true,
                clearable: true,
                optionsAsync: _vm.getLessons
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.lessonId,
                callback: function($$v) {
                  _vm.$set(_vm.model, "lessonId", $$v)
                },
                expression: "model.lessonId"
              }
            }),
            !_vm.edit
              ? _c("FieldInlineSelect", {
                  attrs: {
                    "alert-not-exist": true,
                    "hide-hover": true,
                    label: "בחירת וידאו",
                    clearable: true,
                    filterable: true,
                    placeholder: "הקלד שם וידאו לחיפוש",
                    options: [],
                    "fetch-options": _vm.onSearchVehicles
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.videoId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "videoId", $$v)
                    },
                    expression: "model.videoId"
                  }
                })
              : _vm._e(),
            _c("FieldInlineSelect", {
              attrs: {
                label: "מקבץ חקיקה",
                filterable: true,
                clearable: true,
                optionsAsync: _vm.getlawCollections
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.lawCollectionId,
                callback: function($$v) {
                  _vm.$set(_vm.model, "lawCollectionId", $$v)
                },
                expression: "model.lawCollectionId"
              }
            }),
            _c("FieldInlineSelect", {
              attrs: {
                label: "תקצירי פסיקה",
                filterable: true,
                clearable: true,
                optionsAsync: _vm.getVerdictCollections
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.verdictCollectionId,
                callback: function($$v) {
                  _vm.$set(_vm.model, "verdictCollectionId", $$v)
                },
                expression: "model.verdictCollectionId"
              }
            }),
            _c("FieldInlineSelect", {
              attrs: {
                label: "קטגוריה",
                filterable: true,
                clearable: true,
                optionsAsync: _vm.getCategories
              },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.categoryId,
                callback: function($$v) {
                  _vm.$set(_vm.model, "categoryId", $$v)
                },
                expression: "model.categoryId"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "נושא" },
              model: {
                value: _vm.model.videoCategory,
                callback: function($$v) {
                  _vm.$set(_vm.model, "videoCategory", $$v)
                },
                expression: "model.videoCategory"
              }
            }),
            _vm.edit
              ? _c(
                  "div",
                  { staticClass: "checkbox-container" },
                  [
                    _c(
                      "b-switch",
                      {
                        staticClass: "ltr",
                        on: { input: _vm.onChange },
                        model: {
                          value: _vm.model.status,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "status", $$v)
                          },
                          expression: "model.status"
                        }
                      },
                      [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
                    ),
                    _c(
                      "b-switch",
                      {
                        staticClass: "ltr",
                        on: { input: _vm.onChange },
                        model: {
                          value: _vm.model.isInteractiveVideo,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "isInteractiveVideo", $$v)
                          },
                          expression: "model.isInteractiveVideo"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.model.isInteractiveVideo
                              ? "אינטראקטיבי"
                              : "לא אינטראקטיבי"
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            !_vm.edit
              ? _c(
                  "b-field",
                  { attrs: { label: "חומרי עזר" } },
                  [
                    _c("ckeditor", {
                      attrs: {
                        editor: _vm.editor,
                        placeholder: "'חומרי עזר'",
                        config: _vm.editorConfig
                      },
                      model: {
                        value: _vm.model.supportingMaterials,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "supportingMaterials", $$v)
                        },
                        expression: "model.supportingMaterials"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }