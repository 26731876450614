<template>
  <div class="" v-if="ready">
    <span v-if="loading" class="loading">
      <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
    </span>
    <div class="edit-section">
      <a @click="addLectureStudent">רישום תלמיד קיים להרצאה זו</a>
      <a @click="exportLectureStudents">ייצוא לאקסל</a>
    </div>
    <div v-if="lectureUsers && lectureUsers.length" class="StudentGroups__Container">
      <div class="StudentGroupsWidget__Component" v-for="user in lectureUsers" :key="user.id">
        <div>{{ user.privateName }} {{ user.lastName }} - {{ user.phone1 }} {{ parseDate(user.createdate) }}</div>
        <a @click="removeUserFromLecture(user.id)">ביטול רישום</a>
      </div>
    </div>
    <div v-if="!loading && !lectureUsers.length">אין תלמידים רשומים להרצאה</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";
import { ModalProgrammatic as Modal } from "buefy";
import DialogAddLectureStudent from "../DialogAddLectureStudent.vue";
import { saveAs } from "file-saver";
import CoursesService from "../../services/CoursesService";

export default {
  name: "ClientWidget",
  props: ["ready", "namespace", "fetch"],
  components: {
    ClipLoader,
  },
  data() {
    return {
      lectureUsers: [],
      loading: false,
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["id"]),
    };
  },
  methods: {
    displayDate() {
      return moment(this.CreateDate).format("DD/MM/YYYY");
    },
    addLectureStudent() {
      Modal.open({
        component: DialogAddLectureStudent,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lectureId: this.id
        },
        events: {
          customEvent: () => {
            this.init();
          }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    exportLectureStudents() {
      this.loading = true;
      CoursesService.exportCourseLectureUsers(this.$store.state.auth.course?.id, this.id)
        .then((r) => {
          this.saveFile(r.data, "יצוא סטודנטים רשומים מההרצאה");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    removeUserFromLecture(userId) {
      CoursesService.removeStudendFromLecture(this.id, userId)
        .then(() => {
          this.$buefy.toast.open({
            duration: 8000,
            message: 'הסטודנט נמחק בהצלחה!',
            position: "is-bottom",
            type: "is-success",
          });
          this.init();
        });
    },
    init() {
      if (this.id) {
        this.loading = true;
        CoursesService.getCourseLectureUsers(this.$store.state.auth.course.id, this.id)
          .then((res) => {
            this.lectureUsers = res.data;
          }).finally(() => {
            this.$emit('onReady', { titleCount: this.lectureUsers.length });
            this.loading = false;
          });
        this.$emit("onReady", true);
      }
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.StudentGroups__Container {
  max-height: 400px;
  padding: 0 10px;
  overflow: auto;
}

.StudentGroupsWidget__Component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;
}

.edit-section {
  padding: 0 0 10px 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  a:hover {
    text-decoration: underline;
  }
}

.green {
  background-color: #dff0d8;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
