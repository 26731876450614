<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת הערה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="הזן הערה">
                        <b-input v-model="model.contentToDisplay"></b-input>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import UserService from "@/services/UserService";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";

export default {
    name: "DialogAddRemark",
    props: ["store", "onSuccess"],
    components: {
        FieldInlineSelect
    },
    computed: {
        courseId() {
            return this.store.state.auth.course.id;
        },
        userId() {
            return this.store.state.user.id;
        },
        auth() {
            return this.store.state.user.id;
        }
    },
    created() {
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                contentToDisplay: null,
            }
        };
    },
    methods: {
        save() {
            this.isLoading = true;
            UserService.addRemark({
                ...this.model,
                toUserID: this.userId,
                courseId: this.courseId,
            })
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "הפעולה בוצעה!",
                        duration: 4000,
                    });
                    this.$emit("close");
                    this.onSuccess();
                    // window.location.reload();
                }).catch(() => {
                    Toast.open({
                        type: "is-danger",
                        message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                        duration: 8000,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        getLessons() {
            return () =>
                CoursesService.getCourse(this.courseId, {
                    PageSize: 999,
                    IsAscending: true,
                    PageNumber: 1,
                    SortedBy: "id",
                    ResultType: 3
                }, true).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
                );
        },
    },
};
</script>

<style scoped lang="scss"></style>
