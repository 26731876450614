<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת שאלה לפרק</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-radio v-model="radio" name="name" native-value="manual">
                        ידני
                    </b-radio>
                    <b-radio v-model="radio" name="name" native-value="file">
                        מקובץ
                    </b-radio>
                    <div v-if="radio === 'manual'">
                        <FieldInlineText label="תווית יחידה" v-model="model.unitDisplayName" />

                        <FieldInlineText label="שאלה" v-model="model.question" />

                        <FieldInlineSelect label="תשובה" :filterable="false" :clearable="false" :options="[{ Value: true, Text: 'נכון' },
                        { Value: false, Text: 'לא נכון' }]" required="true" v-model="model.answer" />

                        <FieldInlineText label="פתרון" v-model="model.detailedAnswer" />

                        <FieldInlineText label="רמת קושי" type="number" v-model="model.level" />

                        <div class="checkbox-container" style="margin-top: 10px;">
                            <b-switch class="ltr" v-model="model.status">{{
                                model.status ? "פעיל" : "לא פעיל"
                            }}</b-switch>
                        </div>
                    </div>
                    <div v-else>
                        <b-field label="פורמט להורדה">
                            <b-button @click="downloadExpoFormat">הורד</b-button>
                        </b-field>
                        <b-field label="בחירת קובץ (יש לטעון קובץ בפורמט שניתן בלבד!)">
                            <b-upload v-model="file" class="file-label">
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                    <span class="file-label">בחירת קובץ</span>
                                </span>
                                <span class="file-name" v-if="file">
                                    {{ file.name }}
                                </span>
                            </b-upload>
                        </b-field>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import { saveAs } from "file-saver";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';

export default {
    name: "DialogAddExpoChapter",
    props: ["store"],
    components: {
        FieldInlineText,
        FieldInlineSelect,
    },
    computed: {
        expoChapterId() {
            return this.store.state.expoChapter.id;
        },
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            radio: 'manual',
            file: null,
            model: {
                unitDisplayName: null,
                question: null,
                answer: null,
                detailedAnswer: null,
                level: null,
                status: true
            }
        };
    },
    methods: {
        save() {
            if (this.model.question && this.radio === 'manual') {
                this.isLoading = true;
                CoursesService.createExpoChapterQuestion(this.expoChapterId, {
                    ...this.model,
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            } else {
                const formData = new FormData();
                formData.append('file', this.file);
                CoursesService.importQuestionsToExpoChapter(this.expoChapterId, formData)
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        window.location.reload();
                        this.$emit("close");
                        this.onSuccess();
                    }).catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        this.isLoading = false;
                    })
            }
        },
        downloadExpoFormat() {
            CoursesService.downloadExpoFormat({})
                .then((r) => {
                    this.saveFile(r.data, "פורמט שאלות לפרק ממערכת SimAdmin");
                })
        },
        saveFile(data, filename) {
            const blob = new Blob([data], { type: this.excelMimeType });
            saveAs(blob, `${filename}.xlsx`);
        },
        onIsCreateTask() {
            if (!this.isCreateTask) {
                this.isModalOpen = true;
            }
        },
        onTaskModelClose() {
            this.isModalOpen = false;
            this.isCreateTask = false;
        },
    },
};
</script>

<style lang="scss"></style>
