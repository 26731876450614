import { render, staticRenderFns } from "./DialogUpdateStudentCourses.vue?vue&type=template&id=160bf2be&scoped=true&"
import script from "./DialogUpdateStudentCourses.vue?vue&type=script&lang=js&"
export * from "./DialogUpdateStudentCourses.vue?vue&type=script&lang=js&"
import style0 from "./DialogUpdateStudentCourses.vue?vue&type=style&index=0&id=160bf2be&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160bf2be",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.SimAdmin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('160bf2be')) {
      api.createRecord('160bf2be', component.options)
    } else {
      api.reload('160bf2be', component.options)
    }
    module.hot.accept("./DialogUpdateStudentCourses.vue?vue&type=template&id=160bf2be&scoped=true&", function () {
      api.rerender('160bf2be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DialogUpdateStudentCourses.vue"
export default component.exports