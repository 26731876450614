<template>
    <div class="DialogAddLaw__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">שיוך מקבץ חקיקה ליחידת לימוד</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogAddLaw__Component__Loading">
                    <b-field label="הזן שם">
                        <b-input v-model="model.name"></b-input>
                    </b-field>
                    <FieldInlineSelect label="יחידת לימוד" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getLessons" v-model="model.lessonId" />

                    <FieldInlineSelect label="קטגוריה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getCategories" v-model="model.exerciseCategoryId" />

                    <FieldInlineSelect label="מקבץ חקיקה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getlawCollections" v-model="model.lawCollectionId " />
                    <b-field label="סטטוס">
                        <b-select v-model="model.status">
                            <option value="1">1</option>
                            <option value="0">0</option>
                        </b-select>
                    </b-field>
                    <b-field label="סדר">
                        <b-input type="number" v-model="model.sortOrder"></b-input>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import CoursesService from '../services/CoursesService';
import LessonService from '../services/LessonService';

export default {
    name: "DialogAddLaw",
    props: ["store", "lessonId"],
    components: {
        FieldInlineSelect
    },
    computed: {
        isStatusValid() {
            if (this.submitted) {
                return this.statusId !== undefined;
            }
            return true;
        },
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    created() {
        if (this.lessonId) this.model.lessonId = this.lessonId;
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            model: {
                name: null,
                lessonId: null,
                status: 1,
                exerciseCategoryId: null,
                lawCollectionId: null,
                sortOrder: 1
            }
        };
    },
    methods: {
        save() {
            this.isLoading = true;
            if (this.lessonId) this.model.lessonId = +this.model.lessonId.Value;
            LessonService.addLaw(this.courseId, {
                ...this.model
            })
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "הפעולה בוצעה!",
                        duration: 4000,
                    });
                    this.$emit("close");
                    this.$emit("customEvent")
                    // window.location.reload();
                }).catch(() => {
                    Toast.open({
                        type: "is-danger",
                        message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                        duration: 8000,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        onChange() {
        },
        getCategories() {
            return () =>
                CoursesService.getCategories().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getlawCollections() {
            return () =>
                CoursesService.getLawCollections().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getLaws() {
            return () =>
                CoursesService.getLaws().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getLessons() {
            return () =>
                CoursesService.getCourse(this.store.state.auth.course.id, { PageSize: 999, IsAscending: false, PageNumber: 1, ResultType: 3 }, false).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
                );
        }
    },
};
</script>

<style lang="scss"></style>
