<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">שיוך ספר ליחידת לימוד</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">

                    <!-- <FieldInlineText label="כותרת" v-model="model.title" /> -->

                    <!-- <FieldInlineText label="תת כותרת" v-model="model.subtitle" /> -->

                    <!-- <FieldInlineSelect label="יחידת לימוד" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getLessons" v-model="model.lessonId" /> -->

                    <b-field label="ספר">
                        <FieldInlineSelect :alert-not-exist="true" :hide-hover="true" label="בחירת ספר" :clearable="true"
                            :filterable="true" placeholder="הקלד שם ספר לחיפוש" :options="[]"
                            :fetch-options="onSearchVehicles" @onChange="onChange" v-model="model.bookId" />
                    </b-field>

                    <b-field v-if="!lessonId" label="יחידת לימוד">
                        <FieldInlineSelect label="יחידת לימוד" @onChange="onChange" :filterable="true" :clearable="true"
                            :optionsAsync="getCourseLessons" v-model="model.lessonId" />
                    </b-field>
                    <!-- <FieldInlineSelect label="מקבץ חקיקה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getlawCollections" v-model="model.lawCollectionId" />

                    <FieldInlineSelect label="תקצירי פסיקה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getVerdictCollections" v-model="model.verdictCollectionId" />

                    <FieldInlineSelect label="קטגוריה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getCategories" v-model="model.categoryId" /> -->

                    <!-- <FieldInlineText label="יוצר" v-model="model.author" />

                    <FieldInlineText label="קישור" v-model="model.link" />

                    <FieldInlineText label="תמונה" v-model="model.imageUrl" /> -->

                    <!-- <ckeditor :editor="editor" v-model="model.supportingMaterials" :config="editorConfig"></ckeditor> -->

                    <!-- <div style="display: flex; flex-direction: column; margin-top: 15px;">
                        <div>
                            <b-checkbox style="gap: 10px" v-model="model.status" label="" type="is-link is-light"
                                class="checkbox">
                                {{ model.status ? ' פעיל' : 'לא פעיל' }}
                            </b-checkbox>
                        </div>

                        <div>
                            <b-checkbox style="gap: 10px" v-model="model.isInteractiveVideo" label=""
                                type="is-link is-light" class="checkbox">
                                {{ model.isInteractiveVideo ? 'אינטראקטיבי' : 'לא אינטראקטיבי' }}
                            </b-checkbox>
                        </div>
                    </div> -->

                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
// import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";
import LessonService from "../services/LessonService";

export default {
    name: "DialogAddLessonBook",
    props: ["onSuccess", "store", "lessonId"],
    components: {
        FieldInlineSelect,
        // FieldInlineText
    },
    created() {
        if (this.lessonId) {
            this.model.lessonId = this.lessonId;
        }
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                title: null,
                subtitle: null,
                lessonId: null,
                courseId: null,
                bookId: null,
                author: null,
                link: null,
                imageUrl: null

            },
            // editor: ClassicEditor,
            // editorConfig: {
            // The configuration of the editor.
            // }
        };
    },
    computed: {
        courseId() {
            return this.store.state.auth.course.id;
        }
    },
    methods: {
        save() {
            this.submitted = true;
            if (this.model.bookId) {
                this.isLoading = true;
                if (this.lessonId) this.model.lessonId = +this.model.lessonId.Value;
                LessonService.addLessonBook(this.model.bookId, this.model.lessonId)
                    .then(() => {
                        // this.reports.map((t, idx) => (
                        //     t.activities.unshift(r[idx].data)
                        // ));
                        this.$emit("close");
                        if (this.onSuccess) this.onSuccess();
                        this.$emit('customEvent');
                        // window.location.reload();
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() { },
        getLessons() {
            return () => CoursesService.getCourse(this.store.state.auth.course.id,
                {
                    PageSize: 999,
                    IsAscending: true,
                    PageNumber: 1,
                    ResultType: 3
                },
                false).then((r) => r.data.items.map((u) => ({
                    Value: u.id,
                    Text: `${u.id} - ${u.name}`
                }))
                );
        },
        getCourseLessons() {
            return () =>
                CoursesService.getCourse(
                    this.courseId,
                    {
                        PageSize: 999,
                        IsAscending: false,
                        PageNumber: 1,
                        ResultType: 3
                    },
                    false
                ).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
                );
        },
        getCategories() {
            return () =>
                CoursesService.getCategories().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getlawCollections() {
            return () =>
                CoursesService.getLawCollections().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getVerdictCollections() {
            return () =>
                CoursesService.getVerdictCollections().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        onSearchVehicles({ search, loading, setData, setValue }) {
            if (search.length > 1) {
                loading(true);
                return AdminService.getBooksInBank({
                    Terms: search
                })
                    .then((r) => {
                        if (!r.data.items.length) {
                            setValue({ Value: -1, Text: search });
                        } else {
                            setData(r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.title}` })));
                        }
                    })
                    .finally(() => {
                        loading(false);
                    });
            }

            return null;
        },
    },
};
</script>

<style lang="scss"></style>
