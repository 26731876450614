<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">תצוגה מקדימה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe loading="lazy" title="" :src="link"
                            style="border:none; position: absolute; top:0; left:0; height: 100%; width: 100%;"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;"
                            frameborder="0" allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import LessonService from "../services/LessonService";

export default {
    name: "DialogVideoPreview",
    props: ["link"],
    components: {
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    created() {
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            model: {
                name: null,
                status: 1
            }
        };
    },
    methods: {
        save() {
            if (this.model.name) {
                this.isLoading = true;
                LessonService.addLesson(this.courseId, {
                    ...this.model
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onIsCreateTask() {
            if (!this.isCreateTask) {
                this.isModalOpen = true;
            }
        },
        onTaskModelClose() {
            this.isModalOpen = false;
            this.isCreateTask = false;
        },
    },
};
</script>

<style scoped lang="scss">
.modal-card {
    height: 600px;
}
</style>
