import { render, staticRenderFns } from "./DialogAddExpoChapterQuestion.vue?vue&type=template&id=7d036038&"
import script from "./DialogAddExpoChapterQuestion.vue?vue&type=script&lang=js&"
export * from "./DialogAddExpoChapterQuestion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.SimAdmin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d036038')) {
      api.createRecord('7d036038', component.options)
    } else {
      api.reload('7d036038', component.options)
    }
    module.hot.accept("./DialogAddExpoChapterQuestion.vue?vue&type=template&id=7d036038&", function () {
      api.rerender('7d036038', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DialogAddExpoChapterQuestion.vue"
export default component.exports